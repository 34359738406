import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
    name: 'configSlice',
    initialState: {
        loggedIn: null,
        type: 'hotel',
        partnerName: '',
        managerName: null,
        share: 0,
        referCode: null
    },
    reducers: { 
        updateLogin(state, action){
            state.loggedIn = action.payload
        },
        updatePartnerName(state, action){
            state.partnerName = action.payload
        },
        updateType(state, action){
            state.type = action.payload
        },
        updateManager(state, action){
            state.managerName = action.payload
        },
        updateShare(state, action){
            state.share = action.payload
        },
        updateReferCode(state, action){
            state.referCode = action.payload
        }
    }
})

export const {updatePartnerName, updateLogin, updateType, updateManager, updateShare, updateReferCode} = configSlice.actions;
export default configSlice.reducer;