import { CssBaseline, Stack, ThemeProvider, Typography, createTheme } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import Login from './pages/Login';
import { useCallback, useEffect } from 'react';
import { updateLogin, updateManager, updatePartnerName, updateReferCode, updateShare, updateType } from './store/slices/configSlice';
import { useApiRequest } from './store/Common';
import Navbar from './components/Navbar';
import Empty from './pages/Empty';
import OrdersPage from './pages/OrdersPage';
import CustomerPage from './pages/CustomerPage';
import PickupPage from './pages/PickupPage';
import Dashboard from './pages/Dashboard';
import AgentsPage from './pages/AgentsPage';

function App({loggedIn, type}) {

  const dispatch = useDispatch();
  const {fetchData} = useApiRequest();

  const getConfig = useCallback(async() =>{
    let session = localStorage.getItem('session');
    if(!session){
      dispatch(updateLogin(false));
    }else{
      const resp = await fetchData('app/config');
      if(resp){
        dispatch(updatePartnerName(resp.name));
        dispatch(updateType(resp.type));
        dispatch(updateManager(resp.manager));
        dispatch(updateShare(resp.share));
        dispatch(updateReferCode(resp.referCode));
        dispatch(updateLogin(true));
      }
    }
  },[dispatch, fetchData]);

  useEffect(()=>{
    getConfig();
  },[getConfig]);

  
  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {main: '#d9166f'}
    }
  });

    return(
      <ThemeProvider theme={lightTheme}>
        <CssBaseline/>
          {loggedIn === null && <Stack sx={{height: '100vh', color: '#000', justifyContent: 'center', alignItems: 'center', gap:5}}>
            <img src='/assets/dryfi.png' alt='Dryfi' width='300px' />
            <Stack direction='row' gap={1} sx={{alignItems: 'center'}}>
              <ClipLoader size={15} color='#000' />
              <Typography variant='h6'>Loading Dryfi Partner</Typography>
            </Stack>
          </Stack>}

          {loggedIn === false && <Stack sx={{height: '100vh'}}>
            <Login />
          </Stack>}

          {loggedIn && <Stack sx={{height: '100vh'}}>
            <Navbar/>
            <Stack sx={{flex: 1}}>
              <Routes>
                <Route path='/' element={<Dashboard/>} />
                <Route path='/orders' element={<OrdersPage/>} />
                {(type === "society" || type === "store") && <Route path='/customers' element={<CustomerPage/>} />}
                {type === "hotel" && <Route path='/pickup' element={<PickupPage/>} />}
                {type === "manager" && <Route path='/agents' element={<AgentsPage/>} />}
                <Route path='*' element={<Empty />}/>
              </Routes>
            </Stack>
          </Stack>}

      </ThemeProvider>
    )
}

const mapStateToProps = state => ({
  loggedIn: state.configs.loggedIn,
  type: state.configs.type
});
export default connect(mapStateToProps)(App);
