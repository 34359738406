import { Box, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography} from '@mui/material';
import { LoginSharp, Password } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useApiRequest } from '../store/Common';
import { updateLogin, updateManager, updatePartnerName, updateReferCode, updateShare, updateType } from '../store/slices/configSlice';

function Login(){
    const dispatch = useDispatch();
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [type, setType] = useState('agent');
    const [isSendingOtp, setIsSendingOtp] = useState(false);
    const [isLogginIn, setIsLogginIn] = useState(false);
    const [stage2, setStage2] = useState(false);
    const {postData} = useApiRequest();


    const sendOtp = async() =>{
        if(phone === "" || phone.length !== 10){
            return;
        }
        setIsSendingOtp(true);
        const resp = await postData("app/otp", {phone, type});
        if(resp){
            setStage2(true);
        }
        setIsSendingOtp(false);
    }

    const login = async() =>{
        if(otp === ""){
            return;
        }
        setIsLogginIn(true);
        let obj = {
            phone: phone,
            otp: otp,
            type
        }
        const resp = await postData('app/verifyotp', obj);
        if(resp){
            dispatch(updatePartnerName(resp.name));
            dispatch(updateType(resp.type));
            dispatch(updateManager(resp.manager));
            dispatch(updateReferCode(resp.referCode));
            dispatch(updateShare(resp.share));
            localStorage.setItem('session', resp.session);
            dispatch(updateLogin(true));
        }
        setIsLogginIn(false);
    }

    return (
        <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center',}}>
            <Stack 
                component="form"
                spacing={2}
                direction="column"
                alignItems="center"
                sx={{ width: 350, p: 4, boxShadow: 3, borderRadius: 2 }}
            >
                <img src='/assets/dryfi.png' width='200px' style={{marginBottom: 20}} alt='Dryfi'/>
                <Typography variant='h6'>Partner/ Agent Login</Typography>

                <FormControl fullWidth>
                    <InputLabel>Type</InputLabel>
                    <Select value={type} onChange={(e)=>{setType(e.target.value)}} variant="outlined">
                        <MenuItem value={'partner'}>Partner</MenuItem>
                        <MenuItem value={'agent'}>Agent</MenuItem>
                    </Select>
                </FormControl>
                
                <TextField 
                    fullWidth
                    value={phone}
                    onChange={(e)=>{setPhone(e.target.value)}}
                    label="Partner/ Agent Phone Number" 
                    variant="outlined" 
                    disabled={stage2}
                    type='number'
                    />

                {!stage2 && <LoadingButton
                    fullWidth
                    onClick={sendOtp}
                    loading={isSendingOtp}
                    color='success'
                    variant="contained"
                    loadingPosition="start"
                    startIcon={<Password />}
                    >
                    <span>Send OTP</span>
                </LoadingButton>}
                
                {stage2 && <TextField 
                    fullWidth 
                    label="OTP" 
                    value={otp}
                    onChange={(e)=>{setOtp(e.target.value)}}
                    variant="outlined" 
                    type="number" 
                    />}
                
                {stage2 && <LoadingButton
                    fullWidth
                    onClick={login}
                    loading={isLogginIn}
                    color='success'
                    variant="contained"
                    loadingPosition="start"
                    startIcon={<LoginSharp />}
                    >
                    <span>LOGIN</span>
                </LoadingButton>}
            </Stack>
        </Box>
    );
}

export default Login;