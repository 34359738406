import axios from "axios";
import { useCommonUI } from "../context/UI";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { updateLogin } from "./slices/configSlice";

export const currency = '₹';
export const countryCode = '+91';

export const apiUrl = 'https://partnerapi.dryfi.in/';
const axiosInstance = axios.create({
    baseURL: apiUrl
});

axiosInstance.interceptors.request.use(config => {
    config.headers['x-session'] = localStorage.getItem('session');
    return config;
});

export const useApiRequest = () => {
    const {openSnackbar} = useCommonUI();
    const dispatch = useDispatch();

    const handleError = useCallback((error) => {
        if (error.response && error.response.status === 401) {
            dispatch(updateLogin(false));
            localStorage.removeItem('session');
            openSnackbar("Session Expired", 'error');
        } else {
            openSnackbar(error.message, 'error');
        }
    },[openSnackbar, dispatch])

    const fetchData = useCallback(async (endpoint) => {
        try {
            const response = await axiosInstance.get(endpoint);
            if(!response.data.status){
                throw new Error(response.data.message)
            }
            return response.data;
        } catch (error) {
            handleError(error);
            return null;
        }
    },[handleError])

    const postData = useCallback(async (endpoint, data) => {
        try {
            const response = await axiosInstance.post(endpoint, data);
            if(!response.data.status){
                throw new Error(response.data.message)
            }
            return response.data;
        } catch (error) {
            handleError(error);
            return null;
        }
    },[handleError])

    return { fetchData, postData };
}